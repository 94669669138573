.App {
  text-align: center;
  font-family: Nunito-SemiBold;
}
body {
  background: #111;
}
.App-logo {
  height: 10vmin;
  pointer-events: none;
}
.install {
  position: absolute;
  bottom: 5%;
  left: 5%;
  z-index: 2;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
  }
}
.inputCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 50%;
  justify-content: center;
}

@media (max-width: 1480px) {
  .content {
    font-size: 16px;
    flex-direction: column;
  }
  .App-logocont {
    width: 100% !important;
  }
}

.App-header {
  animation: headerAnim 1s forwards ease-in;
  animation-delay: 0.4s;
  opacity: 0;
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  display: flex;
  flex-direction: row;
}
.App-content {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.content {
  z-index: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 90vh;
  max-width: 75%;
  transition: 0.4s ease-in;
}

.flip {
  opacity: 0;
  transform: scale(0) rotate(90deg);
}
.contentSettings {
  top: 10%;
  max-width: 600px;
  overflow-y: scroll;
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 70%;
  transition: 0.4s ease-out;
  opacity: 0;
  transform: scale(0) rotate(-90deg);
}
/* width */
::-webkit-scrollbar {
  width: 10px;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #222;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #305;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #509;
  border-radius: 5px;
}
.flipSettings {
  opacity: 1;
  transform: scale(1) rotate(0deg);
}

.App-logocont {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-right: 20px;
  max-width: 50%;
  z-index: 1;
}
.App-logocont p {
  font-size: calc(4px + 1.2vmin);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.App-logocont span {
  text-align: right;
}
.App-link {
  color: #c300ff;
  position: absolute;
  left: 20px;
  top: 20px;
}
.App-link span {
  color: white;
}
.ad {
  z-index: 1;
  height: 80vh;
  width: 420px;
  border-radius: 50px;
  margin: 2%;
  opacity: 0;
  background-color: yellow;
}
footer {
  position: fixed;
  bottom: 0;
  user-select: none;
  pointer-events: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
footer svg {
  width: 100%;
  height: 10%;
}
footer svg path {
  fill: rgba(200, 200, 200, 0.2);
}
@keyframes headerAnim {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
