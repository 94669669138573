.bg {
  position: absolute;
  left: 0;
  top: 0;
}
.bgCont {
  z-index: 0;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.bgCont canvas {
  width: 100%;
}
