.searchBox {
  display: flex;
  max-width: 830px;
  width: 100%;
  height: 100%;
  min-width: 600px;
  max-height: 330px;
  align-items: center;
  justify-content: space-around;
  gap: 8px;
  position: relative;
}
@media (max-width: 1048px) {
  .searchBox {
    min-width: 320px;
  }
}

.searchBox textarea {
  width: 100%;
}
.searchButton {
  color: white;
  z-index: 1;
  background: transparent;
  position: absolute;
  right: -1px;
  bottom: -1px;
  font-size: calc(10px + 1vmin);
  padding: 10px;
  border-radius: 16px;

  display: inline-block;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.beackButton {
  color: white;
  z-index: 1;
  background: #a0f;
  font-size: calc(10px + 1vmin);
  padding: 10px;
  border-radius: 16px;

  display: inline-block;
  transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
}
.alarm {
  animation: AlarmAnim 0.4s ease-in;
}
/*hover effect*/
button {
  transform-origin: right bottom;
  border: 2px solid rgba(195, 0, 255, 0);
}
button:hover {
  color: #fff;
  border: 2px solid white;
  /* background-color: #1a1a1a; */
  /* box-shadow: rgba(0, 0, 0, 0.5) 0 10px 20px; */
  transform: scale(1);
}
/*button pressing effect*/
button:active {
  box-shadow: none;
  transform: scale(1.1);
}
.searchButton svg {
  transform: scale(0.8);
}
.searchInput {
  font-family: Nunito-SemiBold;
  font-size: calc(10px + 1vmin);
  resize: none;
  height: 100%;
  min-height: 140px;
  max-height: 620px;
  color: white;
  background-color: rgba(195, 0, 255, 0.33);
  border-color: rgb(255, 255, 255);
  border-radius: 16px;
  border-style: solid;
  border-width: 0px;
  padding: 10px;
  box-sizing: border-box;
}
@keyframes AlarmAnim {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(3deg) scale(1.1);
  }
  to {
    transform: rotate(0deg);
  }
}
