.answer {
  position: relative;
  display: flex;
  align-items: center;
}
.answerTextCont {
  display: flex;
  flex-direction: column;
  padding: 3%;
}
.answerImageCont {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  transition: all 1s;
}
.answerTextCont span {
  text-align: left;
  margin-bottom: 3%;
  opacity: 0;
  animation: AnswerAnim 1s forwards ease-out;
}
.answerImageSharedButtonCont {
  display: flex;
}
.answerImageSharedButtonCont button {
  margin: 5px;
}
.answerButtonCont {
  position: absolute;
  bottom: -15%;
  right: 0;
}
@keyframes AnswerAnim {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}
